

export const ROUTER_LANDING_PAGE = "/"
export const ROUTER_PAYMENT = "/buy"
export const ROUTER_PROMOTION = "/promocion"
export const ROUTER_PAYMENT_COMPLETED = "/payment-completed"

export const ROUTER_INITIAL = "/admin";
export const ROUTER_HOME = "/admin/home";
export const ROUTER_STORE = "/admin/store";
export const ROUTER_VERIFY_EMAIL = "/admin/verify";
export const ROUTER_RESET_PASSWORD_FORM = "/admin/reset-password";

export const LOCALHOST_DOMAIN = "http://localhost:8000/";

//export const LOCAL_DOMAIN = devMode?"http://localhost:8000/":"http://LOCALHOST:8000/";

const devMode = process.env.REACT_APP_MODE === "development"
export const DOMAIN = devMode?"http://localhost:8000/":"https://api.soyyo.digital/";
export const IMAGE_DOMAIN = devMode?"http://localhost:8000/":"https://api.soyyo.digital";


export const ECOMMERCE_DOMAIN = "http://tienda.soyyo.digital/";
export const ECOMMERCE_LOCAL_DOMAIN = "http://localhost/soyyo-ec/";


export const RIA_CHAT_DOMAIN = "https://chat.ria.ventures/api/"

