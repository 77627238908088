import { useEffect, useState } from "react";
import { AiOutlinePlus, AiOutlineMinus, AiOutlineClose } from "react-icons/ai";

export function ShoppingCardProduct(props) {
  const { id, image, title, description, quantity, price } = props;
  const { setQuantity, deleteProduct } = props;

  const [inputQuantity, setInputQuantity] = useState(quantity);
  const [discountedPrice, setDiscountedPrice] = useState(price); // Estado para el precio con descuento
  const MAX_PRODUCTS = 999;

  // Función para calcular el porcentaje de descuento para FULL DESIGN
  const getFullDesignDiscount = (quantity) => {
    if (quantity >= 1 && quantity <= 2) return 0;
    if (quantity >= 3 && quantity <= 5) return 4.41;
    if (quantity >= 6 && quantity <= 10) return 10.29;
    if (quantity >= 11 && quantity <= 20) return 14.71;
    if (quantity >= 21 && quantity <= 50) return 19.12;
    if (quantity >= 51 && quantity <= 100) return 23.53;
    if (quantity >= 101 && quantity <= 500) return 29.41;
    if (quantity >= 501 && quantity <= 1000) return 33.82;
    if (quantity >= 1001 && quantity <= 10000) return 39.71;
    return 0;
  };

  // Función para calcular el porcentaje de descuento para DIGITAL
  const getDigitalDiscount = (quantity) => {
    if (quantity >= 1 && quantity <= 2) return 0;
    if (quantity >= 3 && quantity <= 5) return 8.57;
    if (quantity >= 6 && quantity <= 10) return 14.29;
    if (quantity >= 11 && quantity <= 20) return 20.0;
    if (quantity >= 21 && quantity <= 50) return 25.71;
    if (quantity >= 51 && quantity <= 100) return 31.43;
    if (quantity >= 101 && quantity <= 500) return 37.14;
    if (quantity >= 501 && quantity <= 1000) return 42.86;
    if (quantity >= 1001 && quantity <= 2000) return 71.43;
    if (quantity >= 2001 && quantity <= 10000) return 88.57;
    return 0;
  };

  // Función para calcular el precio con descuento
  const calculateDiscountedPrice = (id, quantity, price) => {
    let discountPercentage = 0;

    if (id === 4) {
        discountPercentage = getFullDesignDiscount(quantity);
    } else if (id === 5) {
        discountPercentage = getDigitalDiscount(quantity);
    }

    const discount = (price * discountPercentage) / 100;
    const discountedPrice = price - discount;

    // Dejar el precio sin redondear
    return discountedPrice;
};

  // Actualizar el precio con descuento cada vez que cambie la cantidad
  useEffect(() => {
    const newPrice = calculateDiscountedPrice(id, inputQuantity, price);
    setDiscountedPrice(newPrice);
  }, [id, inputQuantity, price]);

  function addOneProduct() {
    const final = quantity >= MAX_PRODUCTS ? MAX_PRODUCTS : quantity + 1;
    setQuantity(id, final);
    setInputQuantity(final);
  }

  function removeOneProduct() {
    setQuantity(id, quantity <= 1 ? 1 : quantity - 1);
    setInputQuantity(quantity <= 1 ? 1 : quantity - 1);
  }

  function handleInputQuantity(event) {
    setInputQuantity(event.target.value);

    let input = parseInt(event.target.value);
    if (input && input >= 1 && input <= MAX_PRODUCTS) {
      setQuantity(id, input);
    }
  }

  function handlefixInputQuantity(event) {
    let input = parseInt(event.target.value);
    if (input && input >= 1 && input <= MAX_PRODUCTS) {
      return;
    }

    if (input && input > MAX_PRODUCTS) {
      setInputQuantity(MAX_PRODUCTS);
      setQuantity(id, MAX_PRODUCTS);
      return;
    }
    setInputQuantity(1);
    setQuantity(id, 1);
  }

  useEffect(() => {
    setInputQuantity(quantity);
  }, [quantity]);

  return (
    <tr className="text-sm">
      <td className="py-2 w-fit flex flex-col-reverse sm:flex-row sm:items-center">
        <div className="w-14 h-12 sm:w-16 sm:h-14 flex items-center justify-center bg-white border border-stone-400 p-2 rounded-md relative">
          <button
            aria-label="eliminar item del carrito"
            onClick={() => deleteProduct(id)}
            className="p-0.5 -right-1 -top-1 rounded-full absolute bg-red-500"
          >
            <AiOutlineClose className="text-xs text-stone-100" />
          </button>
          <img className="brightness-95" alt={title} src={image} />
        </div>
        <div className="ml-0 sm:ml-4 flex flex-col">
          <div className="mb-2 sm:mb-0 font-medium text-sm">{title}</div>
          <div className="hidden sm:block text-[13px] text-stone-600">{description}</div>
        </div>
      </td>
      <td className="text-center">Bs {discountedPrice}</td>
      <td>
        <div className="mx-auto w-fit flex">
          <button
            onClick={removeOneProduct}
            className="hidden sm:block rounded-l-md p-[6px] bg-stone-200"
          >
            <AiOutlineMinus className="text-xs text-stone-500" />
          </button>
          <input
            className="w-8 px-1 py-1 text-xs border outline-none text-center border-stone-300"
            type="text"
            value={inputQuantity}
            onBlur={handlefixInputQuantity}
            onChange={handleInputQuantity}
          />
          <button
            onClick={addOneProduct}
            className="hidden sm:block rounded-r-md p-[6px] bg-stone-800"
          >
            <AiOutlinePlus className="text-xs text-stone-200" />
          </button>
        </div>
      </td>
      <td>
        <div className="mx-auto w-20">Bs {(discountedPrice * inputQuantity).toFixed(2)}</div>
      </td>
    </tr>
  );
}
