import { ShoppingCartTable } from "./ShoppingCartTable";


export function ShoppingCart(props) {
  const { products, setProducts } = props;

  return (
    <div className="flex flex-col gap-4 2xl:w-2/2 xl:w-2/2 md:w-full summary">
      <div className="mt-4 flex items-center">
        <div className="ml-2 font-semibold text-lg border-custom">Resumen de Pedido</div>
      </div>
      <div className="min-h-[8rem] overflow-y-auto">
        <ShoppingCartTable products={products} setProducts={setProducts} />
      </div>

    </div>
  );
}
