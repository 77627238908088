import React from "react";
import { Route, Routes } from "react-router";
import LoginForm from "../pages/auth/LoginForm";
import MenuPrincipal from "../pages/home/MenuPrincipal";
import RequireAuth from "../config/RequireAuth";
import { useSelector } from "react-redux";
import NoRequireAuth from "./NoRequireAuth";
import { Store } from "../pages/Store/Catalogo"
import {
  ROUTER_HOME,
  ROUTER_INITIAL,
  ROUTER_LANDING_PAGE,
  ROUTER_PAYMENT,
  ROUTER_PROMOTION,
  ROUTER_PAYMENT_COMPLETED,
  ROUTER_RESET_PASSWORD_FORM,
  ROUTER_VERIFY_EMAIL,
  ROUTER_STORE
} from "./Constant";
import VerifyEmail from "../pages/auth/VerifyEmail";
import ResetPasswordForm from "../pages/auth/ResetPasswordForm";
import { LandingPage } from "../pages/landing_page/LandingPage";
import { PaymentPage } from "../pages/payment_page/PaymentPage";
import { PromotionPage } from "../pages/promotion_page/PaymentPage";
import { PaymentCompleted } from "../pages/payment_page/PaymentCompleted";

const RouterConfig = () => {
  const Token = useSelector((state) => state.login.token);

  return (
    <Routes>
      <Route exact path={ROUTER_LANDING_PAGE} element={<LandingPage />} />
      <Route exact path={ROUTER_PAYMENT} element={<PaymentPage />} />
      <Route exact path={ROUTER_PROMOTION} element={<PromotionPage />} />
      <Route exact path={ROUTER_PAYMENT_COMPLETED} element={<PaymentCompleted />} />
      <Route
        exact
        path={ROUTER_INITIAL}
        element={
          <NoRequireAuth Token={Token}>
            <LoginForm />
          </NoRequireAuth>
        }
      />
      <Route
        exact
        path={ROUTER_HOME}
        element={
          <RequireAuth Token={Token}>
            <MenuPrincipal />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={ROUTER_STORE}
        element={
          <RequireAuth Token={Token}>
            <Store />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={ROUTER_VERIFY_EMAIL}
        element={
          <NoRequireAuth Token={Token}>
            <VerifyEmail />
          </NoRequireAuth>
        }
      />
      <Route
        exact
        path={ROUTER_RESET_PASSWORD_FORM}
        element={
          <NoRequireAuth Token={Token}>
            <ResetPasswordForm />
          </NoRequireAuth>
        }
      />
    </Routes>
  );
};

export default RouterConfig;
