import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ShoppingCart } from "./Components/ShoppingCart";
import { TOKEN_RESULT, useVerifyToken } from "./Hooks/useVerifyToken";

import { useLocation } from "react-router-dom";
import { defaultProducts } from "./Products/defaultProducts";
import { ProductsCardCarroussel } from "../landing_page/Components/ProductsCardCarroussel";
import { ProductsCard } from "../landing_page/Components/ProductsCard";
import { FloatingShoppingCart } from "./Components/FloatingShoppingCart";
import { Navbar } from "../landing_page/Components/Navbar";

import LoadingButton from "../../components/LoadingButton";
import { Dialog } from "@radix-ui/react-dialog";
import { LoginModal } from "./Components/LoginModal";
import TokenRefresher from "../../components/TokenRefresher";
import { AiOutlineFileText } from "react-icons/ai";
import { OrderSummary } from "./Components/OrderSummary";
import { PaymentStageIndicator } from "./Components/PaymentStageIndicator";
import { PaymentUserInformation } from "./Components/PaymentUserInformation";
import { PaymentUserInvoice } from "./Components/PaymentUserInvoice";
import { PaymentSCRUM } from "./Components/PaymentSCRUM";
import { REGEX_PHONE } from "../../config/RegexConstants";
import { getDiscountByVerificationCode } from "../admin_super/Api/discount.api";

import { toast } from "react-toastify";
import { PaymentFormInput } from "./Components/PaymentFormInput";
import { useForm } from "react-hook-form";
import { getProducts } from '../admin_super/Api/products';
import '../../assets/styles/styles.css';


export const PAYMENT_STEPS = {
  Cart: 0,
  /*  Shipment: 1, */
  Payment: 1,
  Completed: 2,
};

export function PromotionPage() {
  const token = useSelector((state) => state.login.token);
  const location = useLocation();

  const [verificationCode, setVerificationCode] = useState(null);
  const [discountRate, setDiscountRate] = useState(null);
  const [discountType, setDiscountType] = useState(null);

  const { isVerified, loading, user } = useVerifyToken(token);

  const [productsArray, setProductsArray] = useState([]);
  const [products, setProducts] = useState({});

  const [openLoginModal, setOpenLoginModal] = useState(false);

  const [stage, setStage] = useState(PAYMENT_STEPS.Cart);

  const [paymentInfo, setPaymentInfo] = useState({
    city: "Santa Cruz",
  });
  const [paymentInvoice, setPaymentInvoice] = useState({});

  const { register, getValues, setValue } = useForm({
    defaultValues: {
      cuponCodigo: "",
    },
  });

  if (isVerified !== TOKEN_RESULT.VALID && stage !== PAYMENT_STEPS.Cart) {
    // Esto parece recursivo, pero no lo es
    // Así si un token vence mientras está en la etapa de pago, lo envía al principio
    setStage(PAYMENT_STEPS.Cart);
  }




  async function handleApplyCoupon() {
    const cuponCode = getValues("cuponCodigo");
    try {
      // Make the call to the API to get discount information
      const discountResponse = await getDiscountByVerificationCode(token, cuponCode);

      const currentDate = new Date();
      const expirationDate = new Date(discountResponse.data.final_date);

      if (expirationDate < currentDate || !discountResponse.data.status) {
        // If expiration date is before current date or status is false, show invalid coupon message
        console.error("Cupón inválido:", cuponCode);
        toast.error("Cupón inválido. Inténtelo de nuevo.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }

      // Check if any product in the cart has "tarjeta" in its title
      const hasTarjetaProduct = Object.values(products).some(
        (product) => product.title.toLowerCase().includes("tarjeta")
      );

      if (!hasTarjetaProduct) {
        setDiscountRate(0);
        toast.error(
          "Este cupón solo aplica a para tarjetas.",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        return;
      }

      toast.success("Cupón aplicado con éxito", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      setDiscountType(discountResponse.data.discount_type);
      setDiscountRate(discountResponse.data.discount_rate);
      setVerificationCode(discountResponse.data.verification_code);
    } catch (error) {
      const discountInput = document.getElementById("discount");
      setDiscountRate(undefined);
      setDiscountType(undefined);
      setVerificationCode(undefined);
      if (discountInput && discountInput.value.trim() !== "") {
        toast.error("Cupón inválido. Inténtelo de nuevo.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  }

  async function handlePayButton() {
    // Check if the coupon code is valid before proceeding
    await handleApplyCoupon();

    if (isVerified === TOKEN_RESULT.VALID) {
      if (stage === PAYMENT_STEPS.Cart) {
        if (Object.values(products).length === 0) {
          toast.error("Agrega items al carrito para comprar", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          return;
        }

        // If a valid coupon code is applied, proceed to the next stage
        setStage(PAYMENT_STEPS.Shipment);
      } else if (stage === PAYMENT_STEPS.Shipment) {
        if (paymentInfoIsValid()) {
          // Ensure the coupon is applied only if applicable products are in the cart
          const hasTarjetaProduct = Object.values(products).some((product) =>
            product.title.toLowerCase().includes("tarjeta")
          );

          if (hasTarjetaProduct) {
            try {
              const cuponCode = getValues("cuponCodigo");
              if (cuponCode) {
                const discountResponse = await getDiscountByVerificationCode(token, cuponCode);
                const currentDate = new Date();
                const expirationDate = new Date(discountResponse.data.final_date);

                if (expirationDate < currentDate || !discountResponse.data.status) {
                  toast.error("Cupón inválido. Inténtelo de nuevo.", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                  return;
                }

                setDiscountType(discountResponse.data.discount_type);
                setDiscountRate(discountResponse.data.discount_rate);
                setVerificationCode(discountResponse.data.verification_code);
              }
            } catch (error) {
              setDiscountRate(undefined);
              setDiscountType(undefined);
              setVerificationCode(undefined);
              toast.error("Cupón inválido. Inténtelo de nuevo.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              return;
            }
          } else {
            // No "tarjeta" products: clear any existing discount
            setDiscountRate(0);
            setDiscountType(undefined);
            setVerificationCode(undefined);
          }

          setStage(PAYMENT_STEPS.Payment);
        } else {
          toast.error("Completa el formulario o verifica que sea válido", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    } else if (isVerified === TOKEN_RESULT.NEED_VERIFICATION) {
      handleUserNeedsVerification();
    } else {
      handleLogInOrRegister();
    }
  }

  function paymentInfoIsValid() {
    return paymentInfo.name && paymentInfo.lastname && paymentInfo.address && paymentInfo.phone.match(REGEX_PHONE);
  }

  function handleLogInOrRegister() {
    setOpenLoginModal(true);
  }
  function handleSuccessfulLoginModal() {
    setStage(PAYMENT_STEPS.Shipment);
  }

  function handleUserNeedsVerification() {
    window.alert("Tu cuenta necesida ser verificada, revisa tu correo electrónico");
  }

  function handleClickImageOrButtonProduct(product) {
    addProduct(product);
  }

  function addProduct(new_product) {
    if (stage !== PAYMENT_STEPS.Cart) {
      toast.error("Vuelve al carrito para agregar más productos", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    const product = products[new_product.id];
    if (product) {
      product.quantity += 1;
    } else {
      new_product.quantity = 1;
    }

    setProducts({ ...products, [new_product.id]: new_product });
  }

  function handleGoBackStage() {
    setStage(stage - 1);
  }

  function renderPaymentStage() {
    switch (stage) {
      case PAYMENT_STEPS.Cart:
        return (
          <PaymentUserInformation 
            user={user} 
            setPaymentInfo={setPaymentInfo} 
            paymentInfo={paymentInfo} 
          />
        );
      case PAYMENT_STEPS.Shipment:
        return (
          <PaymentUserInformation 
            user={user} 
            setPaymentInfo={setPaymentInfo} 
            paymentInfo={paymentInfo} 
          />
        );
      case PAYMENT_STEPS.Payment:
        return (
          <PaymentSCRUM 
            onError={() => setStage(PAYMENT_STEPS.Shipment)} 
            products={products} 
            paymentInfo={paymentInfo} 
            verificationCode={verificationCode} 
          />
        );
      case PAYMENT_STEPS.Completed:
        return <></>;
      default:
        return <>Error raro</>;
    }
  }
  

  function handleButtonClick(event, product) {
    // Evita que otros clics (como el de onClickImageOrButton) se disparen
    event.stopPropagation();

    addProduct(product);

    // Mostrar notificación de éxito
    toast.success("Producto agregado al carrito.", {
      position: "top-center",
      autoClose: 3000, // 3 segundos
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    // Efecto de desplazamiento hacia el carrito
    const carritoElement = document.getElementById("carrito");
    if (carritoElement) {
      carritoElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getProducts(); // Llamada a la API para obtener los productos
        setProductsArray(response.data); // Suponiendo que la respuesta tiene una propiedad `data`
      } catch (error) {
        console.error("Error al obtener productos:", error);
        toast.error("Error al cargar los productos.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    };

    fetchProducts();

    const query = new URLSearchParams(location.search);
    const product = defaultProducts[query.get("id")];

    if (product) {
      addProduct(product);
    }

    const cuponParam = query.get("cupon");
    if (cuponParam) {
      setValue("cuponCodigo", cuponParam);
    }

    const body = document.getElementsByTagName("body")[0];
    body.scrollTo({ top: 0 });
  }, [location.search, setValue]);

  return (
    <>
      <TokenRefresher />
      {/* Carrito flotante removido ya que ahora será siempre visible */}
      {/* <FloatingShoppingCart quantity={Object.keys(products).length} /> */}
  
      <div className="pt-16 font-montserrat">
        <Navbar user={user} forceFixed={true} />
  
        {/* Contenedor general con el carrito visible */}
        <div className="flex">
          {/* Sección principal */}
          <div className="flex-1">
            {/* Catálogo de Productos */}
            <div className="pt-12 pb-6 bg-stone-100">
              <h1 className="px-4 sm:px-8 text-xl sm:text-xl text-center font-bold uppercase">
                Elige tus productos
              </h1>
  
              <div className="mt-6 px-9 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 items-center justify-items-center h-fit gap-y-20 gap-x-0 bg-stone-100 border-2 border-stone-200 shadow-lg p-6 w-5/6 mx-auto rounded-lg">
                {Object.values(defaultProducts)
                  .filter((product) => ![3, 8, 9, 10].includes(product.id)) // Excluye productos con id = 3, 8, 9, 10
                  .map((product, key) => (
                    <ProductsCard
                      key={key}
                      image={product.image}
                      title={product.title}
                      onClickImageOrButton={() => console.log("Imagen o título clickeados")} // Esto es opcional, se puede dejar si necesitas
                      hoverText="¡Agregar al carrito!"
                      buttonText={
                        <div className="flex" onClick={(e) => handleButtonClick(e, product)}>
                          <p className="font-normal">Pide una&nbsp;|&nbsp;</p>
                          <p className="font-bold text-base">{product.price} Bs.</p>
                        </div>
                      }
                      description={product.description}
                      price={product.price}
                      durationTag={product.durationTag}
                      durationYears={product.durationYears}
                    />
                  ))}
              </div>
            </div>
  
            {/* Flujo de pago */}
            <div className="pt-8 bg-stone-100 pb-8">
              <h1 id="payment-title" className="px-4 sm:px-8 text-xl sm:text-xl text-center font-bold uppercase">
                Solicita tus productos SoyYo
              </h1>
  
              <div
                id="carrito"
                className="mt-6 mb-12 px-4 sm:px-8 pt-4 pb-8 w-5/6 bg-white shadow-lg items-center rounded-lg border-1 border-stone-200 mx-auto"
              >
                {/* Indicador de paso del pedido */}
                <PaymentStageIndicator products={products} setStage={setStage} stage={stage} />
  
               {/* Contenedor de los componentes con diseño responsivo */}
              <div className="flex flex-col lg:flex-row items-center justify-center">
                {/* Contenido de renderPaymentStage */}
                <div className="lg:w-1/2">
                  {renderPaymentStage()}
                </div>

                {/* Carrito de compras siempre visible */}
                {stage !== PAYMENT_STEPS.Completed && stage !== PAYMENT_STEPS.Payment && (
                  <div className="lg:w-1/2">
                    <ShoppingCart products={products} setProducts={setProducts} />
                  </div>
                )}
              </div>

                {/* Resumen de Pedido */}
                <div className="mt-4 w-full">
                  <OrderSummary
                    products={Object.values(products)}
                    deliveryCost={paymentInfo.deliveryCost}
                    discountRate={discountRate}
                    discountType={discountType}
                  />
                </div>
  
                {/* Botones de control */}
                <div className="w-full mt-4 flex justify-between">
                  <button
                    onClick={handleGoBackStage}
                    disabled={PAYMENT_STEPS.Cart === stage}
                    className="w-1/3 px-4 py-2 rounded-md border disabled:text-stone-500 text-stone-900 bg-stone-200 font-semibold"
                  >
                    Atrás
                  </button>
  
                  <LoadingButton
                    onClick={handlePayButton}
                    isLoading={loading}
                    className="w-1/3 ml-2 px-4 py-2 rounded-md text-stone-50 bg-indigo-700 font-semibold"
                  >
                    Pagar
                  </LoadingButton>
  
                  <Dialog open={openLoginModal}>
                    <LoginModal setOpenModal={setOpenLoginModal} onSuccessfulLogin={handleSuccessfulLoginModal} />
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
  
        </div>
      </div>
    </>
  );
  
}
