import { OrderSummaryCollapsible } from "./OrderSummaryCollapsible";
import getDiscountByVerificationCode from "../../admin_super/Api/discount.api";
import { CuponTypes } from "../Data/CuponTypes";

export function OrderSummary(props) {
  const { products, deliveryCost, discountRate, discountType } = props;

  // Función para calcular el porcentaje de descuento para FULL DESIGN
  const getFullDesignDiscount = (quantity) => {
    if (quantity >= 1 && quantity <= 2) return 0;
    if (quantity >= 3 && quantity <= 5) return 4.41;
    if (quantity >= 6 && quantity <= 10) return 10.29;
    if (quantity >= 11 && quantity <= 20) return 14.71;
    if (quantity >= 21 && quantity <= 50) return 19.12;
    if (quantity >= 51 && quantity <= 100) return 23.53;
    if (quantity >= 101 && quantity <= 500) return 29.41;
    if (quantity >= 501 && quantity <= 1000) return 33.82;
    if (quantity >= 1001 && quantity <= 10000) return 39.71;
    return 0;
  };

  // Función para calcular el porcentaje de descuento para DIGITAL
  const getDigitalDiscount = (quantity) => {
    if (quantity >= 1 && quantity <= 2) return 0;
    if (quantity >= 3 && quantity <= 5) return 8.57;
    if (quantity >= 6 && quantity <= 10) return 14.29;
    if (quantity >= 11 && quantity <= 20) return 20.0;
    if (quantity >= 21 && quantity <= 50) return 25.71;
    if (quantity >= 51 && quantity <= 100) return 31.43;
    if (quantity >= 101 && quantity <= 500) return 37.14;
    if (quantity >= 501 && quantity <= 1000) return 42.86;
    if (quantity >= 1001 && quantity <= 2000) return 71.43;
    if (quantity >= 2001 && quantity <= 10000) return 88.57;
    return 0;
  };

  // Función para calcular el precio con descuento basado en porcentaje
  const calculateDiscountedPrice = (id, quantity, price) => {
    let discountPercentage = 0;

    if (id === 4) {
      discountPercentage = getFullDesignDiscount(quantity);
    } else if (id === 5) {
      discountPercentage = getDigitalDiscount(quantity);
    }

    const discount = (price * quantity * discountPercentage) / 100;
    const discountedPrice = price * quantity - discount;

    return { discountedPrice, discountPercentage };
  };

  // Calcular subtotal y total con descuentos
  const { totalDiscountAmount, totalWithDiscount } = products.reduce(
    (acc, product) => {
      const { discountedPrice } = calculateDiscountedPrice(
        product.id,
        product.quantity,
        product.price
      );

      const discountAmount = product.price * product.quantity - discountedPrice;

      return {
        totalDiscountAmount: acc.totalDiscountAmount + discountAmount,
        totalWithDiscount: acc.totalWithDiscount + discountedPrice,
      };
    },
    { totalDiscountAmount: 0, totalWithDiscount: 0 }
  );

  const delivery = deliveryCost ?? 0;

  // Calcular descuentos globales (cupones)
  let descuento = 0;
  if (discountType === CuponTypes.Percentage) {
    descuento = (totalWithDiscount * discountRate) / 100;
  } else if (discountType === CuponTypes.Price) {
    descuento = discountRate;
  }
  descuento = parseFloat(descuento.toFixed(2));

  // Total a pagar final
  let totalApagar = totalWithDiscount - descuento + delivery;
  totalApagar = parseFloat(totalApagar.toFixed(2));

  return (
    <div className="px-6 py-3 bg-stone-100">
      <div className="mt-3 mb-3">
        <OrderSummaryCollapsible>
          <div className="gap-1 mt-2 flex flex-col text-stone-800">
            {products.map((product, index) => {
              const { discountedPrice } = calculateDiscountedPrice(
                product.id,
                product.quantity,
                product.price
              );
              return (
                <div key={index} className="text-sm flex justify-between">
                  <p>
                    {product.title} &nbsp;
                    <strong className="font-medium">({product.quantity})</strong>
                  </p>
                  <p>Bs {discountedPrice.toFixed(2)}</p>
                </div>
              );
            })}
          </div>
        </OrderSummaryCollapsible>
      </div>

      <div className="gap-y-2 w-full text-sm sm:text-base grid grid-cols-2">
        <h3 className="text-stone-800">Subtotal</h3>
        <p className="text-right text-stone-800 whitespace-nowrap">
          Bs {totalWithDiscount.toFixed(2)}
        </p>
        <h3 className="text-stone-800">Envío</h3>
        <p className="text-right text-stone-800 whitespace-nowrap">
          Bs {delivery.toFixed(2)}
        </p>
        <h3 className="font-bold text-stone-800">Total</h3>
        <p className="text-right font-semibold whitespace-nowrap">
          Bs {(totalWithDiscount + delivery).toFixed(2)}
        </p>
        <h3 className="text-green-700">Descuento</h3>
        <p className="text-right text-green-700 whitespace-nowrap">
          Bs {descuento.toFixed(2)}
        </p>
        <h3 className="font-bold text-stone-800">Total a Pagar</h3>
        <p className="text-right font-semibold whitespace-nowrap">
          Bs {totalApagar <= 0 ? "0.00" : totalApagar.toFixed(2)}
        </p>
      </div>
    </div> 
  );
}

export function computeSubtotalProducts(products) {
  return products.reduce((sum, product) => {
    return sum + product.price * product.quantity;
  }, 0);
}
